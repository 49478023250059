<template>
    <div class="container-fluid">

        <!-- start page title -->
        <div class="row">
            <div class="col-12">
                <div class="page-title-box">
                    <div class="page-title-right">
                        <ol class="breadcrumb m-0">
                            <li class="breadcrumb-item">
                                <router-link :to="{name: 'dashboard'}">Home</router-link>
                            </li>
                            <li v-if="component==='consignments'" class="breadcrumb-item active">Consignments</li>
                            <li v-if="component==='steps' || component==='print-consignment'" class="breadcrumb-item">
                                <router-link :to="{name: 'consignments'}">Consignments
                                </router-link>
                            </li>
                            <li v-if="component==='steps'" class="breadcrumb-item active">{{process_name}}</li>
                            <li v-if="component==='print-consignment'" class="breadcrumb-item active">Print</li>
                        </ol>
                    </div>
                    <h4 class="page-title" v-if="component==='consignments'">Consignments</h4>
                    <h4 class="page-title" v-if="component==='steps'">Consignment #  <span class="text-muted">{{process_name}}</span></h4>
                    <h4 class="page-title" v-if="component==='print-consignment'">Print</h4>
                </div>
            </div>
        </div>
        <!-- end page title -->
        <router-view></router-view>
    </div>
</template>

<script>
    export default {
        name: "StepsLayout",
        data() {
            return {
                component: null,
                process_name: null,
            }
        },
        watch: {
            $route(to, from) {
                this.component = to.name;
                this.process_name = this.$route.params.consignmentid
            }
        },
        created() {
            this.component = this.$route.name;
            this.process_name = this.$route.params.consignmentid
        }
    }
</script>

<style scoped>

</style>